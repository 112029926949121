import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/header";
import FooterSection from "./components/footer";

const Layout: React.FC = () => {
  return (
    <div className="w-full min-h-screen pt-20">
      <Header />
      <main>
        <Outlet />
      </main>
      <div className="bg-secondary w-full overflow-hidden">
        <FooterSection />
      </div>
    </div>
  );
};

export default Layout;
