import { ReactSVG } from "react-svg";

export type AdvantageCardProps = {
  icon: string;
  title: string;
  description: string;
};

const AdvantageCard = ({ icon, title, description }: AdvantageCardProps) => {
  return (
    <div className="w-full flex flex-col items-start justify-normal bg-white cursor-pointer hover:bg-secondary transition-colors duration-500 p-5 rounded-lg group lg:max-w-[374px]">
      <div className="size-14 bg-gray-light group-hover:bg-white rounded-full flex items-center justify-center">
        <ReactSVG
          src={icon}
          className="text-dark group-hover:stroke-[10] group-hover:text-secondary transition-all duration-200"
        />
      </div>

      <p className="pt-8 pb-3 text-xl md:text-3xl font-semibold text-dark group-hover:text-[#ecedee] transition-colors duration-200">
        {title}
      </p>

      <p className="text-gray-500 text-sm md:text-base font-normal leading-snug group-hover:text-[#e2dded] transition-colors duration-200">
        {description}
      </p>
    </div>
  );
};

export default AdvantageCard;
