import AdvantageCard from "./advantage-card";
import useConfig from "../hooks/useConfig";

const AdvantagesSection = () => {
  const { data } = useConfig();
  return (
    <div className="w-full p-6 pt-12 lg:pt-28 lg:max-w-landing mx-auto">
      <p className="text-center text-primary text-[10px] md:text-[13px] font-semibold uppercase leading-[18px] tracking-wide">
        {data?.advantagesSection?.heading}
      </p>
      <h2 className="text-center text-secondary text-2xl font-bold leading-10 md:text-4xl py-2">
        {data?.advantagesSection?.title}
      </h2>
      <p className="text-center text-gray-500 text-xs md:text-lg font-normal leading-snug mb-10">
        {data?.advantagesSection?.subtitle}
      </p>

      <div className="flex flex-col md:grid md:grid-cols-3 gap-4 w-full">
        {data?.advantagesSection?.advantages?.map((item, idx) => (
          <AdvantageCard key={idx} {...item} />
        ))}
      </div>
    </div>
  );
};

export default AdvantagesSection;
