import useConfig from "../hooks/useConfig";
import FeatureCard from "./feature-card";

const FeaturesSection = () => {
  const { data } = useConfig();
  return (
    <div className="w-full flex flex-col items-center justify-center gap-y-3">
      <h2 className="text-center text-secondary text-2xl font-bold leading-10 md:text-4xl">
        {data?.payNowSection?.title}
      </h2>
      <p className="text-center text-gray-500 text-xs md:text-lg font-normal leading-snug mb-10">
        {data?.payNowSection?.description}
      </p>

      <div className="w-full flex flex-col items-center justify-center gap-y-4 p-4">
        {data?.payNowSection?.features?.map((item, idx) => (
          <FeatureCard key={idx} {...item} />
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;
