import { create } from "zustand";

type SubscriptionType = "Hybrid" | "Local";

type SubscriptionState = {
  activeSubscription: SubscriptionType;
  setActiveSubscription: (subscription: SubscriptionType) => void;
};

const useSubscriptionStore = create<SubscriptionState>((set) => ({
  activeSubscription: "Hybrid",
  setActiveSubscription: (subscription) =>
    set({ activeSubscription: subscription }),
}));

export default useSubscriptionStore;
