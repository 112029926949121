import React from "react";
import SubscriptionsTabs from "./subscriptions-tabs";
import PricingCard, { PricingCardProps } from "./pricing-card";
import useConfig from "../hooks/useConfig";
import useSubscriptionStore from "../state/subscriptions";

const Subscriptions = () => {
  const { data } = useConfig();

  const { activeSubscription } = useSubscriptionStore();

  const hybridPlans = data?.offersSection?.hybrid_plans as PricingCardProps[];
  const localPlans = data?.offersSection?.local_plans as PricingCardProps[];

  hybridPlans[1].isPopular = true;

  console.log("hybridPlans : ", hybridPlans);

  return (
    <div className="w-full flex flex-col items-center justify-center gap-y-4">
      <h2 className="text-center text-secondary text-2xl font-bold leading-10 md:text-4xl">
        {data?.offersSection?.title}
      </h2>
      <p className="text-center text-gray-500 text-xs md:text-lg font-normal leading-snug">
        {data?.offersSection?.description}
      </p>

      <div className="w-full flex flex-col items-center justify-center gap-y-4 p-4">
        <div className="my-10 w-full flex justify-center items-center">
          <SubscriptionsTabs />
        </div>

        {activeSubscription === "Hybrid" && (
          <div className="flex flex-col items-center justify-end lg:grid lg:grid-cols-3 lg:place-items-start gap-4 w-full pt-11">
            {hybridPlans?.map((option, index) => (
              <PricingCard
                key={index}
                title={option.title}
                price={option.price}
                originalPrice={option.originalPrice}
                description={option.description}
                features={option.features}
                discountLabel={option.discountLabel}
                buttonText={option.buttonText}
                subtitle={option.subtitle}
                isPopular={option.isPopular}
              />
            ))}
          </div>
        )}

        {activeSubscription === "Local" && (
          <div className="flex flex-col items-center justify-center w-full">
            {localPlans?.map((option, index) => (
              <PricingCard
                key={index}
                title={option.title}
                price={option.price}
                originalPrice={option.originalPrice}
                description={option.description}
                features={option.features}
                discountLabel={option.discountLabel}
                buttonText={option.buttonText}
                subtitle={option.subtitle}
                isPopular={option.isPopular}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Subscriptions;
