import { ReactSVG } from "react-svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TestimonialCard, { Testimonial } from "./testimonial-card";
import useConfig from "../hooks/useConfig";

// Wrapping the returned array in a Fragment
const TestimonialWrapper = ({
  testimonials,
}: {
  testimonials: Testimonial[];
}) => (
  <div className="flex flex-col items-center justify-end lg:grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-8 w-full rtl:lg:place-items-start ltr:lg:place-items-end">
    {testimonials?.map((item, index) => (
      <TestimonialCard key={index} {...item} />
    ))}
  </div>
);

const Testimonials = () => {
  const { data } = useConfig();
  const testimonials = data.testimonialsSection.testimonials as Testimonial[];

  const chunkArray = <T,>(array: T[], chunkSize: number): T[][] => {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, [] as T[][]);
  };

  const groupedTestimonials = chunkArray(testimonials, 3);

  return (
    <div className="w-full py-24 px-9 relative">
      <div className="flex-col justify-start items-start gap-2 inline-flex">
        <div className="relative text-[#FFCB05] text-2xl md:text-5xl font-bold md:leading-[60px]">
          <ReactSVG
            src="/assets/icons/quote-mark.svg"
            className="absolute -top-8 -left-8 opacity-30 text-white h-16 rtl:-right-8 rtl:left-auto"
            height={65}
            viewBox="0 0 81 65"
          />
          {data?.testimonialsSection?.title}
        </div>
        <div className="text-[#ebe8f3] text-xs md:text-lg font-normal md:leading-7">
          {data?.testimonialsSection?.description}
        </div>
      </div>

      <Carousel
        showArrows={false}
        autoPlay
        infiniteLoop
        emulateTouch
        showIndicators
        showStatus={false}
        swipeable={false}
        className="w-full my-12 testimonials-slider-container"
      >
        {groupedTestimonials?.map((item, index) => (
          <TestimonialWrapper key={index} testimonials={item} />
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
