import { PlayCircleIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import Button from "./button";
import useConfig from "../hooks/useConfig";
import { Trans } from "react-i18next";
import { ReactNode } from "react";

function HighlightedText({ children }: { children?: ReactNode }) {
  return (
    <span className="text-primary bg-secondary rounded-lg px-2">
      {children}
    </span>
  );
}

const HeroSection = () => {
  const { data } = useConfig();
  return (
    <section className="mt-10 relative md:mt-16 flex flex-col items-center justify-center w-full md:max-w-5xl mx-auto">
      <h1 className="text-center font-semibold text-3xl leading-[44px] md:leading-[90px] md: text-[#1b163b] md:text-6xl order-1">
        <Trans
          i18nKey="heroSection.title"
          components={{ highlightedText: <HighlightedText /> }}
        />
      </h1>

      <video
        autoPlay
        loop
        muted
        playsInline
        className="my-6 shadow rounded-lg order-2 md:order-4 w-[412px] md:w-full border border-gray-200"
      >
        <source src="https://efawtara.com/preview.mp4" type="video/mp4" />
      </video>

      <div className="min-w-52 md:w-full gap-y-2 flex flex-col items-center justify-center order-3 md:order-3 md:flex-row md:justify-center gap-x-2">
        <Button className="w-full md:w-fit flex flex-row items-center justify-center gap-x-2 whitespace-nowrap">
          <a href="https://app.efawtara.com/company/register" target="_blank">
            {data?.heroSection?.buttons.signUp}
          </a>
          <ArrowRightIcon className="size-6" strokeWidth={2} />
        </Button>

        <a
          href="https://www.youtube.com/watch?v=-deW8UCAhuE&t=1s"
          target="_blank"
          className="w-fit flex flex-row items-center justify-center gap-x-2 text-secondary cursor-pointer"
        >
          <p className="text-base font-semibold">
            {data?.heroSection?.buttons.learnMore}
          </p>
          <PlayCircleIcon className="size-6" />
        </a>
      </div>

      <p className="w-[332px] md:w-[712px] text-center text-gray-500 text-[11px] md:text-xl font-medium leading-normal order-4 md:order-2 md:pt-2 md:pb-6">
        {data?.heroSection?.description}
      </p>
    </section>
  );
};

export default HeroSection;
