import { ReactSVG } from "react-svg";
import { cn } from "../lib/utils";

const UseCaseCard = ({
  img,
  label,
  value,
  className,
}: {
  img: string;
  label: string;
  value: string;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "w-52 flex flex-col items-center justify-center gap-y-2 bg-white py-4 px-7 rounded-[30px] shadow text-center",
        className
      )}
    >
      <img src={img} className="w-16 h-16 rounded-full" alt="" />

      <p className="text-secondary text-base font-bold leading-[19px]">
        {label}
      </p>

      <div className="w-full flex flex-row items-center justify-start gap-x-2">
        <ReactSVG
          src="/assets/icons/heart-rounded.svg"
          className="text-secondary"
        />
        <p className="text-black text-base font-normal leading-normal">
          {value}
        </p>
      </div>
    </div>
  );
};

export default UseCaseCard;
