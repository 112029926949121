import React, { ButtonHTMLAttributes } from "react";
import { cn } from "../lib/utils";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    <button
      className={cn(
        "px-4 py-2 bg-secondary rounded-lg justify-start items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
