import Button from "./button";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { ReactSVG } from "react-svg";
import { cn } from "../lib/utils";
import { useTranslation } from "react-i18next";

export type FeatureProps = {
  icon: string;
  title: string;
  description: string;
  heading: string;
  image: string;
  className?: string;
  innerBG?: string;
  iconColor?: string;
  ctaClassName?: string;
  iconContainerClassName?: string;
  headingClassName?: string;
};

const FeatureCard = ({
  icon,
  className,
  innerBG,
  iconColor,
  title,
  description,
  heading,
  ctaClassName,
  image,
  iconContainerClassName,
  headingClassName,
}: FeatureProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        "w-full p-6 lg:py-18 lg:px-24 rounded-3xl flex flex-col items-start justify-start md:justify-between lg:items-center",
        className
      )}
    >
      <div
        className={cn(
          "px-6 py-2 w-full lg:w-[383px] xl:w-[450px] flex-col justify-center items-center",
          innerBG
        )}
      >
        <img src={image} alt="" className="w-full h-full" />
      </div>

      <div className="lg:w-[389px]">
        <div
          className={cn(
            "size-12 rounded-[14px] p-3 mb-8 hidden lg:block",
            iconContainerClassName
          )}
        >
          <ReactSVG src={icon} className={iconColor} />
        </div>

        <p
          className={cn(
            "text-xs font-bold uppercase leading-none tracking-wide text-secondary hidden lg:block",
            headingClassName
          )}
        >
          {heading}
        </p>

        <p
          className={cn(
            "text-[#0f1113] text-xl lg:text-[40px] font-semibold leading-normal lg:leading-[48px] my-6"
          )}
        >
          {title}
        </p>

        <p className="text-justify text-gray-500 text-[13px] lg:text-[15px] font-normal leading-normal">
          {description}
        </p>

        <Button
          className={cn(
            "bg-transparent w-fit flex flex-row rtl:flex-row-reverse items-center justify-center gap-x-2 mt-6",
            ctaClassName
          )}
          onClick={() =>
            window.open("https://app.efawtara.com/company/register", "_blank")
          }
        >
          <span>{t("payNowSection.features.0.button")}</span>
          <ArrowRightIcon className="size-6" />
        </Button>
      </div>
    </div>
  );
};

export default FeatureCard;
