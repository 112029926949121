import React, { useEffect, useState } from "react";
import { cn } from "../lib/utils";
import UseCaseCard from "./use-case-card";
import { useTranslation } from "react-i18next";

const UsecasesSection = ({
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
  className,
}: {
  direction?: "left" | "right";
  speed?: "fast" | "normal" | "slow";
  pauseOnHover?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();

  const data = [
    {
      img: "assets/images/usecase1.png",
      label: t("heroSection.cases.smallBusiness.label"),
      value: t("heroSection.cases.smallBusiness.value"),
    },
    {
      img: "assets/images/usecase2.png",
      label: t("heroSection.cases.ecommerce.label"),
      value: t("heroSection.cases.ecommerce.value"),
    },
    {
      img: "assets/images/usecase3.png",
      label: t("heroSection.cases.freelance.label"),
      value: t("heroSection.cases.freelance.value"),
    },
    {
      img: "assets/images/usecase4.png",
      label: t("heroSection.cases.autoEntrepreneur.label"),
      value: t("heroSection.cases.autoEntrepreneur.value"),
    },
    {
      img: "assets/images/usecase5.png",
      label: t("heroSection.cases.educationalInstitutions.label"),
      value: t("heroSection.cases.educationalInstitutions.value"),
    },
    {
      img: "assets/images/usecase6.png",
      label: t("heroSection.cases.serviceProviders.label"),
      value: t("heroSection.cases.serviceProviders.value"),
    },
  ];

  const containerRef = React.useRef<HTMLDivElement>(null);
  const scrollerRef = React.useRef<HTMLUListElement>(null);

  useEffect(() => {
    addAnimation();
  }, []);
  const [start, setStart] = useState(false);
  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }
  const getDirection = () => {
    if (containerRef.current) {
      if (direction === "left") {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "forwards"
        );
      } else {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "reverse"
        );
      }
    }
  };
  const getSpeed = () => {
    if (containerRef.current) {
      if (speed === "fast") {
        containerRef.current.style.setProperty("--animation-duration", "20s");
      } else if (speed === "normal") {
        containerRef.current.style.setProperty("--animation-duration", "40s");
      } else {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      }
    }
  };
  return (
    <div
      ref={containerRef}
      className={cn(
        "md:hidden scroller relative z-20  max-w-7xl overflow-hidden  [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]",
        className
      )}
    >
      <ul
        ref={scrollerRef}
        className={cn(
          " flex min-w-full shrink-0 gap-4 py-4 w-max flex-nowrap",
          start && "ltr:animate-scroll-ltr rtl:animate-scroll-rtl",
          pauseOnHover && "hover:[animation-play-state:paused]"
        )}
      >
        {data.map((item, idx) => (
          <UseCaseCard {...item} key={idx} />
        ))}
      </ul>
    </div>
  );
};
export default UsecasesSection;
