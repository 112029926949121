import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useDropdown } from "../hooks/useDropdown";
import useConfig from "../hooks/useConfig";
import { useTranslation } from "react-i18next";

interface Language {
  code: string;
  label: string;
  flag: string;
}

export function LanguageDropdown() {
  const { data } = useConfig();
  const { i18n } = useTranslation();
  const languages = data?.header?.menu?.languages as Language[];

  const selectedLanguage = localStorage.getItem("efawtara-language");

  const { isOpen, setIsOpen, toggleDropdown, dropdownRef } = useDropdown();

  const handleSelectLanguage = (language: Language) => {
    setIsOpen(false);
    i18n.changeLanguage(language.code);
    localStorage.setItem("efawtara-language", language.code);
  };

  const currentLanguage =
    languages.find((lan) => lan.code === selectedLanguage) ??
    (languages.find((lan) => lan.code === "ar") as Language);
  return (
    <div className="relative w-40" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between w-full md:px-4 py-2 text-xl font-normal md:font-medium text-[#303541] md:text-base bg-transparent rounded-md hover:text-secondary focus:outline-none cursor-pointer"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span className="flex items-center">
          <img
            src={currentLanguage.flag}
            alt={`${currentLanguage.label} flag`}
            width={24}
            height={24}
            className="ltr:mr-2 rtl:ml-2"
          />
          <span>{currentLanguage.label}</span>
        </span>
        <ChevronDownIcon
          className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.ul
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto focus:outline-none"
            role="listbox"
          >
            {languages.map((language) => (
              <li
                key={language.code}
                className={`${
                  currentLanguage.code === language.code
                    ? "text-secondary bg-primary"
                    : "text-gray-900"
                } cursor-pointer select-none relative py-2 px-4 hover:bg-primary hover:text-secondary`}
                onClick={() => handleSelectLanguage(language)}
                role="option"
                aria-selected={currentLanguage.code === language.code}
              >
                <div className="flex items-center">
                  <img
                    src={language.flag}
                    alt={`${language.label} flag`}
                    width={24}
                    height={24}
                    className="ltr:mr-2 rtl:ml-2"
                  />
                  <span
                    className={`${
                      currentLanguage.code === language.code
                        ? "font-semibold"
                        : "font-normal"
                    } block truncate`}
                  >
                    {language.label}
                  </span>
                </div>
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
}
