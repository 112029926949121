import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

export type Testimonial = {
  company: string;
  testimonial: string;
  name: string;
  position: string;
};

const TestimonialCard = ({
  name,
  company,
  testimonial,
  position,
}: Testimonial) => {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <div className="bg-white w-full lg:min-w-[341px] lg:min-h-[400px] h-full p-8 rounded-lg shadow flex flex-col select-none">
      <img
        src={company}
        id="testimonial-company-logo"
        alt=""
        className="mb-8 rtl:ml-auto ltr:mr-auto"
      />

      <div className="flex flex-row rtl:flex-row-reverse items-start justify-between flex-1 flex-grow">
        <div className="w-10 flex flex-row items-start justify-start rtl:justify-end">
          <ReactSVG
            src="/assets/icons/quote-mark-mini.svg"
            className="text-primary w-3 h-3 rtl:transform rtl:-scale-x-100"
            color="#FFD008"
            height={20}
            viewBox="0 0 81 20"
          />
        </div>

        <div className="w-full h-full flex flex-col items-start justify-between relative">
          <p
            dir={i18n.dir()}
            className="text-[#0f1113] text-sm font-normal leading-7 text-left rtl:text-right"
          >
            {testimonial}
          </p>

          <div className="flex flex-row rtl:flex-row-reverse justify-start  items-start gap-6 mt-6 w-full">
            {/* <img
              className="size-16 rounded-full  object-cover"
              id="testimonial-name-logo"
              src="https://www.shutterstock.com/image-photo/smiling-african-american-millennial-businessman-600nw-1437938108.jpg"
            /> */}
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex w-full">
              <p className="text-[#0f1113] text-lg font-bold leading-7 text-left rtl:text-right w-full">
                {name}
              </p>
              <p className="text-gray-500 text-sm font-normal leading-tight text-left rtl:text-right w-full">
                {position}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
