import React from "react";
import { ReactSVG } from "react-svg";
import useConfig from "../hooks/useConfig";

const CtaSection = () => {
  const { data } = useConfig();
  const ctaData = data?.footer?.cta;
  return (
    <div className="bg-[#fff8da] w-full px-4 py-32 md:py-20 flex flex-col items-center justify-center rounded-3xl gap-y-4 relative overflow-hidden md:flex-row md:items-center md:justify-between md:gap-y-0">
      <ReactSVG
        src="/assets/illustrations/hand-money.svg"
        className="absolute -left-6 -top-6 w-36 h-36 rotate-[43deg] md:rotate-0 md:relative md:w-auto"
      />

      <div className="w-fit flex flex-col items-center justify-center gap-y-2">
        <h2 className="text-center text-[#0f1113] text-[22px] md:text-4xl font-bold">
          {ctaData?.title}
        </h2>
        <p className="text-center text-gray-500 text-xs md:text-lg font-medium leading-normal">
          {ctaData?.description}
        </p>

        <div className="w-full flex flex-col items-center justify-center gap-y-2 md:flex-row md:gap-y-0 md:gap-x-2">
          <button className="text-secondary text-sm font-semibold leading-normal px-4 py-2 bg-[#ffd008] rounded-lg justify-center items-center gap-1.5 inline-flex">
            {ctaData?.start}
          </button>
          <button className="text-secondary text-sm font-semibold leading-normal px-4 py-2 bg-white border border-[#ffd008] rounded-lg justify-center items-center gap-1.5 inline-flex">
            {ctaData?.demo}
          </button>
        </div>
      </div>

      <ReactSVG
        src="/assets/illustrations/invoice.svg"
        className="absolute right-0 bottom-0 w-32 h-32 md:relative md:w-auto"
      />
    </div>
  );
};

export default CtaSection;
