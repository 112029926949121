import { ReactSVG } from "react-svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../lib/utils";

export interface PricingCardProps {
  title: string;
  price: string;
  originalPrice: string;
  subtitle: string;
  description: string;
  features: string[];
  discountLabel?: string;
  buttonText: string;
  onButtonClick?: () => void;
  isPopular?: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  originalPrice,
  description,
  features,
  discountLabel,
  buttonText,
  onButtonClick,
  subtitle,
  isPopular = false,
}) => {
  const { t } = useTranslation();
  const cardContent = (
    <div className="w-full lg:w-[350px] min-h-[450px] relative bg-[#f6f6f6] rounded-[10px] border border-zinc-200 overflow-hidden px-8 py-7">
      <div className="flex-col justify-start items-start gap-2 flex">
        <div className="text-zinc-900 text-lg font-bold leading-normal">
          {title}
        </div>
        <div className="flex-col justify-start items-start gap-[21px] flex">
          <div className="flex-col justify-start items-start gap-2 flex">
            <div className="text-zinc-900 text-[42px] font-bold leading-[48px]">
              {price}
            </div>

            <div className="relative inline-block text-zinc-900 text-[38px] font-light leading-[48px]">
              <span className="relative z-10">{originalPrice}</span>
              <span className="w-full left-0 top-[37.48px] absolute origin-top-left rotate-[-10deg] h-[3px] bg-red-500" />
            </div>
          </div>
          <div className="self-stretch text-zinc-600 text-base font-normal leading-relaxed">
            {description}
          </div>
        </div>

        <button
          className={cn(
            "w-full px-4 py-2 text-secondary hover:bg-secondary bg-white hover:text-white rounded-lg justify-center items-center gap-1.5 inline-flex transition-colors duration-200 my-6",
            isPopular
              ? "bg-secondary text-white hover:bg-secondary/90"
              : "text-secondary hover:bg-secondary hover:text-white bg-white"
          )}
        >
          <a
            href="https://app.efawtara.com/company/register"
            target="_blank"
            className="text-base font-semibold leading-normal"
          >
            {buttonText}
          </a>
        </button>

        <h1 className="text-zinc-900 text-base font-bold leading-7">
          {subtitle} :
        </h1>
        <div className="flex-col justify-start items-start gap-1 flex">
          {features.map((feature, index) => (
            <div
              key={index}
              className="justify-start items-center gap-2 inline-flex"
            >
              <ReactSVG
                src="/assets/icons/check.svg"
                className="text-green-500 size-6 h-full"
                strokeWidth={3}
              />
              <div className="text-zinc-600 text-base font-medium leading-[34px]">
                {feature}
              </div>
            </div>
          ))}
        </div>
      </div>

      {discountLabel && (
        <div className="absolute right-0 top-0 h-16 w-16 rtl:right-auto rtl:left-0">
          <p className="absolute transform rotate-45 rtl:-rotate-45 bg-primary text-center text-secondary py-1 right-[-35px] top-[32px] w-[170px] text-xl font-black leading-normal rtl:right-auto rtl:left-[-35px]">
            {discountLabel}
          </p>
        </div>
      )}
    </div>
  );

  if (isPopular) {
    return (
      <div className="relative pt-10 pb-1 px-1 rounded-[12px] bg-gradient-to-r from-[#6155f0] to-[#3b1c84] mt-0 md:-mt-10 w-full md:w-auto">
        <div className="absolute top-0 left-0 right-0 text-center text-white font-bold py-2 text-sm">
          {t("offersSection.isPopular")}
        </div>
        {cardContent}
      </div>
    );
  }

  return cardContent;
};

export default PricingCard;
