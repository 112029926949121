import { ReactSVG } from "react-svg";
import useConfig from "../hooks/useConfig";
import { Link } from "react-router-dom";

const FooterSection = () => {
  const { data } = useConfig();
  const footerData = data.footer;
  return (
    <footer className="w-full flex flex-col items-center justify-center">
      <div className="w-full flex flex-col items-center justify-center py-6 px-8 gap-y-4 mx-auto lg:max-w-landing">
        <ReactSVG
          src="/assets/logos/efawtara.svg"
          className="size-14 md:size-24"
        />
        <h1 className="text-center text-white text-lg font-bold md:text-4xl">
          {footerData?.headline}
        </h1>
        <p className="text-center text-white text-xs md:text-lg font-normal">
          {footerData?.description}
        </p>
      </div>

      <div className="w-full border border-t border-x-0 border-b-0 border-t-white">
        <div className="w-full flex flex-col-reverse gap-y-2 md:gap-y-0 md:flex-row items-center justify-center md:justify-between py-6 mx-auto lg:max-w-[1560px] md:px-4">
          <p className="text-[#ebe8f3] text-xs md:text-base font-normal">
            {footerData?.copyright}
          </p>

          <div className="w-full md:w-fit flex flex-row items-center justify-center gap-x-1">
            <p className="text-[#ebe8f3] text-xs md:text-base font-normal whitespace-nowrap w-fit flex flex-row items-center gap-x-1">
              {footerData?.productBy}&nbsp;
              <a
                href="https://guiddini.com/"
                target="_blank"
                className="w-fit flex flex-row items-center gap-x-1"
              >
                <span className="text-white text-xs md:text-base font-bold">
                  Guiddini
                </span>
                <ReactSVG src="/assets/logos/guiddini.svg" className="size-5" />
              </a>
            </p>
          </div>

          <ul className="justify-start items-center gap-4 hidden lg:inline-flex">
            <a
              href="https://app.efawtara.com/company/register"
              target="_blank"
              className="text-[#ebe8f3] text-base font-normal"
            >
              {footerData?.startNow}
            </a>
            <a href="#offers" className="text-[#ebe8f3] text-base font-normal">
              {footerData?.offers}
            </a>
            <Link
              to="/conditiongeneraldutilisation"
              className="text-[#ebe8f3] text-base font-normal"
            >
              {footerData?.terms}
            </Link>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
