import { useTranslation } from "react-i18next";
import AdvantagesSection from "./components/advantage-section";
import ContactSection from "./components/contact";
import CtaSection from "./components/cta";
import FaqSection from "./components/faq";
import FeaturesSection from "./components/features-section";
import FloatingUseCases from "./components/floating-useCases";
import HeroSection from "./components/hero";
import Subscriptions from "./components/subscriptions";
import Testimonials from "./components/testimonials";
import UsecasesSection from "./components/usecases";

const App = () => {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const direction = i18n.language === "ar" ? "left" : "right";

  return (
    <>
      <div
        className="bg-[#f6f6f6] w-full md:h-[942px] overflow-hidden relative"
        id="about"
      >
        <div className="w-full lg:max-w-landing h-full p-4 flex flex-col items-start justify-start mx-auto">
          <HeroSection />
          <UsecasesSection speed="normal" direction={direction} />
          <UsecasesSection speed="normal" direction={direction} />
        </div>
        <FloatingUseCases />
      </div>
      <div className="bg-white w-full lg:max-w-landing mx-auto overflow-hidden mt-12 lg:mt-28">
        <FeaturesSection />
      </div>

      <div className="bg-[#f6f6f6] w-full mx-auto overflow-hidden mt-12 lg:mt-28">
        <AdvantagesSection />
      </div>

      <div
        className="bg-white w-full lg:max-w-landing mx-auto overflow-hidden mt-12 lg:mt-28"
        id="offers"
      >
        <Subscriptions />
      </div>

      <div className="bg-secondary w-full overflow-hidden mt-12 lg:mt-28">
        <div className=" lg:max-w-landing mx-auto ">
          <Testimonials />
        </div>
      </div>

      <div className="bg-white w-full lg:max-w-landing mx-auto overflow-hidden mt-12 lg:mt-28">
        <FaqSection />
      </div>

      <div
        className="bg-[#f6f6f6] w-full overflow-hidden mt-12 lg:mt-28"
        id="contact"
      >
        <div className=" lg:max-w-landing mx-auto ">
          <ContactSection />
        </div>
      </div>

      <div className="bg-white w-full lg:max-w-landing mx-auto overflow-hidden my-12 lg:my-28">
        <div className="mx-auto">
          <CtaSection />
        </div>
      </div>
    </>
  );
};

export default App;
