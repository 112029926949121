import React from "react";
import { ReactSVG } from "react-svg";
import useConfig from "../hooks/useConfig";

const ContactSection = () => {
  const { data } = useConfig();
  const contactSection = data?.contactSection;
  return (
    <div className="w-full py-24 px-9 flex flex-col gap-y-2">
      <h2 className="text-left rtl:text-right text-secondary text-2xl font-bold leading-10 md:text-4xl">
        {contactSection?.title}
      </h2>
      <p className="text-left rtl:text-right text-gray-500 text-xs md:text-lg font-normal leading-snug">
        {contactSection?.description}
      </p>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center pt-10 gap-y-3 lg:gap-y-0">
        <div className="grid grid-cols-2 gap-6 items-start justify-items-start">
          {/*  */}
          <div className="w-fit flex flex-col items-start justify-start gap-y-2">
            <div className="size-12 bg-secondary rounded-full flex items-center justify-center">
              <ReactSVG src="/assets/icons/email.svg" />
            </div>

            <h1 className="text-[#2a3342] text-xl font-bold">
              {contactSection?.form?.email}
            </h1>
            <a
              href="mailto:support@efawtara.com"
              className="text-[#556987] text-sm font-medium"
            >
              support@efawtara.com
            </a>
          </div>

          {/*  */}
          <div className="w-fit flex flex-col items-start justify-start gap-y-2">
            <div className="size-12 bg-secondary rounded-full flex items-center justify-center">
              <ReactSVG src="/assets/icons/phone.svg" />
            </div>

            <h1 className="text-[#2a3342] text-xl font-bold">
              {contactSection?.form?.phoneNumber}
            </h1>
            <a
              href="tel:+2130550545299"
              className="text-[#556987] text-sm font-medium flex flex-row"
            >
              <span className="hidden ltr:block">+</span>2130550545299
              <span className="hidden rtl:block">+</span>
            </a>
          </div>

          {/*  */}
          <div className="w-fit flex flex-col items-start justify-start gap-y-2">
            <div className="size-12 bg-secondary rounded-full flex items-center justify-center">
              <ReactSVG src="/assets/icons/location-pin.svg" />
            </div>

            <h1 className="text-[#2a3342] text-xl font-bold">
              {contactSection?.info.office}
            </h1>
            <a
              target="_blank"
              href="https://maps.app.goo.gl/yCjZmWdaXRNGUH699"
              className="text-[#556987] text-sm font-medium"
            >
              {contactSection?.info.officeAddress}
            </a>
          </div>

          {/*  */}
          <div className="w-fit flex flex-col items-start justify-start gap-y-2">
            <div className="size-12 bg-secondary rounded-full flex items-center justify-center">
              <ReactSVG src="/assets/icons/socials.svg" />
            </div>

            <h1 className="text-[#2a3342] text-xl font-bold">
              {contactSection?.info.socials}
            </h1>
            <div className="w-full flex flex-row items-center justify-start gap-x-4">
              <a
                href="https://www.facebook.com/efawtara"
                target="_blank"
                className="w-9 h-9 relative bg-[#ebe8f3] rounded-full flex justify-center items-center"
              >
                <ReactSVG src="/assets/icons/facebook.svg" />
              </a>

              <a
                href="https://www.instagram.com/efawtara"
                target="_blank"
                className="w-9 h-9 relative bg-[#ebe8f3] rounded-full flex justify-center items-center"
              >
                <ReactSVG src="/assets/icons/instagram.svg" />
              </a>

              <a
                href="https://www.linkedin.com/company/efawtara"
                target="_blank"
                className="w-9 h-9 relative bg-[#ebe8f3] rounded-full flex justify-center items-center"
              >
                <ReactSVG src="/assets/icons/linkedin.svg" />
              </a>
            </div>
          </div>
        </div>

        <div className="bg-[#ebe8f3] rounded-md shadow py-5 px-4 focus-within:outline-none focus:outline-none outline-none flex flex-col gap-y-6">
          <div className="">
            <label
              htmlFor="name"
              className="block text-[#333f51] text-sm font-medium mb-2"
            >
              {contactSection?.form.name}
            </label>

            <input
              type="text"
              id="name"
              name="name"
              placeholder={contactSection?.form.namePlaceholder}
              className="bg-white rounded-lg outline-none shadow border border-[#d5dae1] justify-start items-center gap-2 inline-flex text-[#667084] text-sm font-normal px-4 py-2 w-full"
            />
          </div>

          <div className="">
            <label
              htmlFor="phone"
              className="block text-[#333f51] text-sm font-medium mb-2"
            >
              {contactSection?.form.phoneNumber}
            </label>

            <input
              type="text"
              id="phone"
              name="phone"
              placeholder={contactSection?.form.phoneNumberPlaceholder}
              className="bg-white rounded-lg outline-none shadow border border-[#d5dae1] justify-start items-center gap-2 inline-flex text-[#667084] text-sm font-normal px-4 py-2 w-full"
            />
          </div>

          <div className="">
            <label
              htmlFor="name"
              className="block text-[#333f51] text-sm font-medium mb-2"
            >
              {contactSection?.form.subject}
            </label>

            <input
              type="text"
              id="subject"
              name="subject"
              placeholder={contactSection?.form.subjectPlaceholder}
              className="bg-white rounded-lg outline-none shadow border border-[#d5dae1] justify-start items-center gap-2 inline-flex text-[#667084] text-sm font-normal px-4 py-2 w-full"
            />
          </div>

          <div className="">
            <label
              htmlFor="name"
              className="block text-[#333f51] text-sm font-medium mb-2"
            >
              {contactSection?.form.message}
            </label>

            <textarea
              id="message"
              name="message"
              placeholder={contactSection?.form.subjectPlaceholder}
              className="resize-y bg-white rounded-lg outline-none shadow border border-[#d5dae1] justify-start items-center gap-2 inline-flex text-[#667084] text-sm font-normal px-4 py-2 w-full"
            />
          </div>

          <button className="w-full px-4 py-2 bg-[#3b1c84] rounded-lg justify-center items-center gap-1.5 inline-flex text-white text-sm font-semibold leading-normal">
            {contactSection?.form.button}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
