import { useState, useEffect } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import Button from "./button";
import { Link } from "react-router-dom";
import useConfig from "../hooks/useConfig";
import { LanguageDropdown } from "./LanguageDropdown";

const Header = () => {
  const { data } = useConfig();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const dropdownItems = Object.entries(data?.header?.menu?.languages || {}).map(
    ([key, value]) => ({
      label: value,
      value: key,
    })
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check initial scroll position

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isMobileMenuOpen]);

  return (
    <header
      className={`w-full flex flex-row items-center justify-between fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isSticky ? "bg-white/30 backdrop-blur-3xl shadow-md" : "bg-[#f6f6f6] "
      } py-4 px-4 lg:px-0`}
    >
      <div
        className={`w-full max-w-landing mx-auto flex flex-row items-center justify-between`}
      >
        <Link to="/">
          <img
            src="/assets/logos/Logo.svg"
            className="w-36 hidden ltr:block"
            alt="Logo"
          />
          <img
            src="/assets/logos/efawtara-ar.png"
            className="w-36 hidden rtl:block"
            alt="Logo"
          />
        </Link>
        <div className="lg:hidden">
          <Bars3Icon
            className="text-secondary size-8 cursor-pointer"
            strokeWidth={2}
            onClick={toggleMobileMenu}
          />
        </div>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex flex-row items-center justify-between w-fit gap-x-12">
          <a
            href="#about"
            className="text-center text-[#303541] text-base font-semibold leading-relaxed"
          >
            {data.header.menu.about}
          </a>
          <a
            href="#offers"
            className="text-center text-[#303541] text-base font-semibold leading-relaxed"
          >
            {data.header.menu.offers}
          </a>
          <a
            href="#contact"
            className="text-center text-[#303541] text-base font-semibold leading-relaxed"
          >
            {data.header.menu.contact}
          </a>
        </ul>

        <ul className="hidden lg:flex flex-row items-center justify-between w-fit">
          <LanguageDropdown />
          <a
            href="https://app.efawtara.com/company/login"
            target="_blank"
            className="text-[#3b1c84] text-base font-semibold leading-normal pl-10 pr-4 rtl:pr-10 rtl:pl-4"
          >
            {data.header.menu.login}
          </a>
          <Button
            onClick={() => {
              window.open(
                "https://app.efawtara.com/company/register",
                "_blank"
              );
            }}
          >
            {data.header.menu.signUp}
          </Button>
        </ul>

        {/* Full-screen Mobile Menu */}
        {isMobileMenuOpen && (
          <nav className="lg:hidden fixed inset-0 bg-white z-40">
            <div className="flex justify-end p-6">
              <XMarkIcon
                className="text-black size-6 cursor-pointer"
                onClick={toggleMobileMenu}
              />
            </div>
            <div className="px-6 flex flex-col h-[calc(100vh-80px)]">
              <div className="flex flex-col space-y-6 mb-4">
                <a
                  href="#about"
                  className="text-[#303541] text-xl font-normal"
                  onClick={toggleMobileMenu}
                >
                  {data.header.menu.about}
                </a>
                <a
                  href="#offers"
                  className="text-[#303541] text-xl font-normal"
                  onClick={toggleMobileMenu}
                >
                  {data.header.menu.offers}
                </a>
                <a
                  href="#contact"
                  className="text-[#303541] text-xl font-normal"
                  onClick={toggleMobileMenu}
                >
                  {data.header.menu.contact}
                </a>

                <LanguageDropdown />
              </div>
              <div className="flex flex-col space-y-4">
                <a
                  href="https://app.efawtara.com/company/login"
                  target="_blank"
                  className="text-[#3b1c84] text-base font-semibold leading-normal"
                >
                  {data.header.menu.login}
                </a>
                <Button
                  onClick={() => {
                    window.open(
                      "https://app.efawtara.com/company/register",
                      "_blank"
                    );
                  }}
                >
                  {data.header.menu.signUp}
                </Button>
              </div>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
