import { motion, AnimatePresence } from "framer-motion";
import { cn } from "../lib/utils";
import { ReactSVG } from "react-svg";

interface AccordionProps {
  title: string;
  content: string;
  isOpen: boolean;
  onToggle: () => void;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  isOpen,
  onToggle,
}) => {
  return (
    <div
      className={cn(
        "w-full flex flex-col items-center justify-center cursor-pointer p-6 rounded-md",
        isOpen ? "bg-secondary" : "border border-gray-500 bg-white "
      )}
      onClick={onToggle}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <h3
          className={cn(
            isOpen ? "text-white" : "text-[#0f1728]",
            "text-lg md:text-xl font-semibold"
          )}
        >
          {title}
        </h3>

        {isOpen ? (
          <ReactSVG src="/assets/icons/minus-circle.svg" />
        ) : (
          <ReactSVG src="/assets/icons/plus-circle.svg" />
        )}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="w-full mt-6"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0, margin: 0 },
            }}
            transition={{ duration: 0.5 }}
          >
            <p className="text-[#c2b9d9] text-base text-start mt-2">
              {content}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { Accordion };
