import useConfig from "../hooks/useConfig";
import SubscriptionTab from "./tab";

const SubscriptionsTabs = () => {
  const { data } = useConfig();
  return (
    <div className="w-fit bg-[#f6f6f6] flex flex-row items-center justify-center py-2.5 px-4 rounded-full">
      <SubscriptionTab
        label={data?.offersSection?.types?.hybrid}
        plan="Hybrid"
      />
      <SubscriptionTab label={data?.offersSection?.types?.local} plan="Local" />
    </div>
  );
};

export default SubscriptionsTabs;
