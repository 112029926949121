import { cn } from "../lib/utils";
import useSubscriptionStore from "../state/subscriptions";

const SubscriptionTab = ({
  label,
  plan,
}: {
  label: string;
  plan: "Hybrid" | "Local";
}) => {
  const { activeSubscription, setActiveSubscription } = useSubscriptionStore();
  const isActive = activeSubscription === plan;

  const inactiveTab = "text-neutral-500 font-medium";
  const activeTab = "bg-primary text-secondary font-semibold";

  return (
    <div
      className={cn(
        "md:max-w-72 px-7 py-1.5 rounded-full text-center text-xs md:text-sm leading-[19px] tracking-tight cursor-pointer",
        isActive ? activeTab : inactiveTab
      )}
      onClick={() => setActiveSubscription(plan)}
    >
      {label}
    </div>
  );
};

export default SubscriptionTab;
