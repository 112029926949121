import React, { useState } from "react";
import { Accordion } from "./accordion";
import useConfig from "../hooks/useConfig";

const FaqSection = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const { data } = useConfig();
  return (
    <div className="w-full px-9">
      <h2 className="text-center text-secondary text-2xl font-bold leading-10 md:text-4xl">
        {data?.faqSection?.title}
      </h2>
      <p className="text-center text-[#667084] text-xs md:text-lg font-normal leading-snug">
        {data?.faqSection?.description}
      </p>

      <div className="w-full flex flex-col items-center justify-center py-10 gap-y-5">
        {data?.faqSection?.questions?.map((item, index) => (
          <Accordion
            key={index}
            title={item.title}
            content={item.content}
            isOpen={openIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
