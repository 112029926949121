import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "./ar.json";
import fr from "./fr.json";
import en from "./en.json";

const resources = {
  ar: {
    translation: ar,
  },
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
};

const lang = localStorage.getItem("efawtara-language");

i18n.use(initReactI18next).init({
  resources,
  lng: lang || "ar",
  fallbackLng: "ar",
  compatibilityJSON: "v3",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
