import { useTranslation } from "react-i18next";

const useConfig = () => {
  const { t } = useTranslation();

  const data = {
    header: {
      menu: {
        about: t("header.menu.about"),
        offers: t("header.menu.offers"),
        contact: t("header.menu.contact"),
        language: t("header.menu.language"),
        login: t("header.menu.login"),
        signUp: t("header.menu.signUp"),
        languages: t("header.menu.languages", { returnObjects: true }),
      },
    },
    heroSection: {
      title: t("heroSection.title"),
      description: t("heroSection.description"),
      buttons: {
        signUp: t("heroSection.buttons.signUp"),
        learnMore: t("heroSection.buttons.learnMore"),
      },
    },
    payNowSection: {
      title: t("payNowSection.title"),
      description: t("payNowSection.description"),
      features: [
        {
          title: t("payNowSection.features.0.title"),
          description: t("payNowSection.features.0.description"),
          button: t("payNowSection.features.0.button"),
          icon: "/assets/icons/organize.svg",
          image: "/assets/images/dashboard.png",
          heading: t("payNowSection.features.0.heading"),
          ctaClassName: "text-secondary",
          innerBG: "bg-[#9585f3]/5",
          iconColor: "text-secondary",
          className: "bg-[#f6f6f6] lg:flex-row-reverse rtl:lg:flex-row",
          iconContainerClassName: "bg-[#9585f3]/5",
        },
        {
          title: t("payNowSection.features.1.title"),
          description: t("payNowSection.features.1.description"),
          button: t("payNowSection.features.1.button"),
          icon: "/assets/icons/doc.svg",
          image: "/assets/images/dashboard2.png",
          heading: t("payNowSection.features.1.heading"),
          ctaClassName: "text-primary",
          innerBG: "bg-[#fffae6]",
          iconColor: "text-primary",
          className: "bg-[#fffae6] lg:flex-row rtl:lg:flex-row-reverse",
          iconContainerClassName: "bg-white",
          headingClassName: "text-primary",
        },
      ],
    },
    advantagesSection: {
      title: t("advantagesSection.title"),
      subtitle: t("advantagesSection.subtitle"),
      heading: t("advantagesSection.heading"),
      advantages: [
        {
          title: t("advantagesSection.advantages.0.title"),
          description: t("advantagesSection.advantages.0.description"),
          icon: "/assets/icons/stars.svg",
        },
        {
          title: t("advantagesSection.advantages.1.title"),
          description: t("advantagesSection.advantages.1.description"),
          icon: "/assets/icons/zap-fast.svg",
        },
        {
          title: t("advantagesSection.advantages.2.title"),
          description: t("advantagesSection.advantages.2.description"),
          icon: "/assets/icons/clock.svg",
        },
        {
          title: t("advantagesSection.advantages.3.title"),
          description: t("advantagesSection.advantages.3.description"),
          icon: "/assets/icons/bell.svg",
        },
        {
          title: t("advantagesSection.advantages.4.title"),
          description: t("advantagesSection.advantages.4.description"),
          icon: "/assets/icons/magic.svg",
        },
        {
          title: t("advantagesSection.advantages.5.title"),
          description: t("advantagesSection.advantages.5.description"),
          icon: "/assets/icons/settings.svg",
        },
      ],
    },
    offersSection: {
      title: t("offersSection.title"),
      description: t("offersSection.description"),
      hybrid_plans: t("offersSection.hybrid_plans", { returnObjects: true }),
      local_plans: t("offersSection.local_plans", { returnObjects: true }),
      types: {
        hybrid: t("offersSection.types.hybrid"),
        local: t("offersSection.types.local"),
      },
    },
    testimonialsSection: {
      title: t("testimonialsSection.title"),
      description: t("testimonialsSection.description"),
      testimonials: t("testimonialsSection.testimonials", {
        returnObjects: true,
      }),
    },
    faqSection: {
      title: t("faqSection.title"),
      description: t("faqSection.description"),
      questions: [
        {
          title: t("faqSection.questions.0.question"),
          content: t("faqSection.questions.0.answer"),
        },
        {
          title: t("faqSection.questions.1.question"),
          content: t("faqSection.questions.1.answer"),
        },
        {
          title: t("faqSection.questions.2.question"),
          content: t("faqSection.questions.2.answer"),
        },
      ],
    },
    contactSection: {
      title: t("contactSection.title"),
      description: t("contactSection.description"),
      form: {
        namePlaceholder: t("contactSection.namePlaceholder"),
        emailPlaceholder: t("contactSection.emailPlaceholder"),
        phoneNumberPlaceholder: t("contactSection.phoneNumberPlaceholder"),
        subjectPlaceholder: t("contactSection.subjectPlaceholder"),
        messagePlaceholder: t("contactSection.messagePlaceholder"),
        name: t("contactSection.name"),
        email: t("contactSection.email"),
        phoneNumber: t("contactSection.phoneNumber"),
        subject: t("contactSection.subject"),
        message: t("contactSection.message"),
        button: t("contactSection.send"),
      },
      info: {
        email: t("contactSection.info.email"),
        phone: t("contactSection.info.phone"),
        socials: t("contactSection.socials"),
        office: t("contactSection.office"),
        officeAddress: t("contactSection.officeAddress"),
      },
    },
    footer: {
      cta: {
        title: t("footer.cta.title"),
        description: t("footer.cta.description"),
        demo: t("footer.cta.demo"),
        start: t("footer.cta.start"),
      },
      headline: t("footer.headline"),
      description: t("footer.description"),
      copyright: t("footer.headline"),
      productBy: t("footer.productBy"),
      startNow: t("footer.startNow"),
      offers: t("footer.offers"),
      terms: t("footer.terms"),
    },
  };

  return { data };
};

export default useConfig;
