import React from "react";
import UseCaseCard from "./use-case-card";
import { cn } from "../lib/utils";
import { useTranslation } from "react-i18next";

const FloatingUseCases = () => {
  const { t } = useTranslation();
  const data = [
    {
      img: "assets/images/usecase1.png",
      label: t("heroSection.cases.smallBusiness.label"),
      value: t("heroSection.cases.smallBusiness.value"),
      style: "rotate-[5.35deg] animate-float",
    },
    {
      img: "assets/images/usecase2.png",
      label: t("heroSection.cases.ecommerce.label"),
      value: t("heroSection.cases.ecommerce.value"),
      style: "rotate-[-3.78deg] animate-float",
    },
    {
      img: "assets/images/usecase3.png",
      label: t("heroSection.cases.freelance.label"),
      value: t("heroSection.cases.freelance.value"),
      style: "rotate-[10.23deg] animate-float",
    },
    {
      img: "assets/images/usecase4.png",
      label: t("heroSection.cases.autoEntrepreneur.label"),
      value: t("heroSection.cases.autoEntrepreneur.value"),
      style: "rotate-[5.35deg] animate-float",
    },
    {
      img: "assets/images/usecase5.png",
      label: t("heroSection.cases.educationalInstitutions.label"),
      value: t("heroSection.cases.educationalInstitutions.value"),
      style: "rotate-[7.43deg] animate-float",
    },
    {
      img: "assets/images/usecase6.png",
      label: t("heroSection.cases.serviceProviders.label"),
      value: t("heroSection.cases.serviceProviders.value"),
      style: "rotate-[-17.22deg] animate-float",
    },
  ];
  return (
    <div className="hidden lg:flex relative lg:absolute inset-0 justify-between items-center pointer-events-none 2xl:max-w-[1800px] 2xl:mx-auto">
      <div className="flex flex-col">
        {data.slice(0, 3).map((item, idx) => (
          <UseCaseCard {...item} key={idx} className={cn(item.style)} />
        ))}
      </div>

      {/* Right Column */}
      <div className="flex flex-col gap-y-4 transform translate-x-6">
        {data.slice(3).map((item, idx) => (
          <UseCaseCard {...item} key={idx} className={cn(item.style)} />
        ))}
      </div>
    </div>
  );
};

export default FloatingUseCases;
